/* Основные стили приложения */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

/* Темы приложения (интеграция с Telegram) */
.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.app.light {
  background-color: var(--tg-theme-bg-color, #ffffff);
  color: var(--tg-theme-text-color, #000000);
}

.app.dark {
  background-color: var(--tg-theme-bg-color, #212121);
  color: var(--tg-theme-text-color, #ffffff);
}

/* Контент приложения */
.app-content {
  flex: 1;
  overflow-y: auto;
  position: relative;
}

/* Навигация */
.navigation {
  height: 60px;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--tg-theme-secondary-bg-color, #f0f0f0);
  border-top: 1px solid var(--tg-theme-hint-color, #cccccc);
}

.app.dark .navigation {
  background-color: var(--tg-theme-secondary-bg-color, #333333);
  border-top: 1px solid var(--tg-theme-hint-color, #555555);
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.333%;
  height: 100%;
  cursor: pointer;
  color: var(--tg-theme-hint-color, #999999);
}

.nav-item.active {
  color: var(--tg-theme-button-color, #3390ec);
}

.nav-icon {
  font-size: 24px;
  margin-bottom: 4px;
}

.nav-label {
  font-size: 12px;
  text-align: center;
}

/* Страница карты */
.map-container {
  height: 100%;
  width: 100%;
}

/* Загрузка и ошибки */
.loading, .error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
  padding: 20px;
}

.error {
  color: #d32f2f;
}